import { Languages } from "../languages/index";
import { useRouter } from "next/router";

interface LocaleType {
  locale: "pt" | "es" | "en";
}

export default function useTranslation() {
  const router = useRouter();
  const { locale } = router as LocaleType;

  function t(key: string) {
    if (!Languages[locale][key]) {
      console.warn(`No string '${key}' for locale '${locale}'`);
    }

    return Languages[locale][key] || "";
  }

  return { t };
}
