const ComoFunciona = {
  ComoFuncionaTitle: "¿Cómo funciona el sorteo gratuito?",
  ComoFuncionaSubtitle:
    "El sorteo gratuito funciona para sorteos que tengam hasta 300 comentarios.",
  ComoFuncionaStep1: "Ingrese su @ de usuario de Instagram.",
  ComoFuncionaStep2: "Selecciona tu publicación y configúrala.",
  ComoFuncionaStep3:
    "Inicie su sesión con Facebook y permita leer comentarios desde su perfil de Instagram.",
  faq: "Preguntas frecuentes",
  comoFuncionaAnswer:
    "El sorteo gratuito funciona para dibujos que tienen  menos de 300 comentarios y la conexión entre Facebook e Instagram es obligatoria, esa es la única forma en que podemos subir todos los comentarios sin cobrar tarifas. Si tu sorteo tiene más de 5.000 comentarios, deberás realizar el sorteo en la nube.",
  howDoesTheClouldDrawWork: "¿Cómo funciona Cloud Giveaway?",
  howDoesTheClouldDrawWorkAnswer:
    "La carga en la nube se usa para sorteos que tienen  más de 300 comentarios  y tienen un costo simbólico porque son más complejos y requieren una mayor infraestructura. Con este tipo de sorteo, sus comentarios se subirán directamente a la nube, lo que le permite seguir subiendo incluso sin tener la pestaña InstaSorteio abierta en su navegador. Además, al usar la carga en la nube, no es necesario que se conecte entre Facebook e Instagram.",
  whyDoINeedToConnectMyFacebook:
    "¿Por qué necesito conectar una página de Facebook a Instagram?",
  whyDoINeedToConnectMyFacebookAnswer:
    "Este proceso es necesario porque es la única forma en que podemos subir el 100% de los comentarios de la publicación de forma totalmente gratuita para nuestros usuarios. Además, esta es una de las formas más seguras de acceder a una publicación de Instagram, ya que la conexión se realiza dentro de Facebook y sigue los estándares de privacidad de la red.",
  howDoIConnectMyFacebook: "¿Cómo conecto Facebook a Instagram?",
  howDoIConnectMyFacebookAnswer:
    "1 - Clic en el botón de páginas, en Facebook → cree una página, o acceda a una ya creada;  2 - Haga clic en Configurar página y luego en Instagram;  3 - Ahora solo complete con el nombre de usuario y su contraseña de Instagram … y ya esta. ¡Entra en el instasorteio.com.br y haz tu sorteo!",
  areThePaymentMethodsSafe: "¿Son seguros los métodos de pago?",
  areThePaymentMethodsSafeAnswer:
    "Los usuarios que opten por la carga en la nube pueden completar la transacción con tranquilidad. Nuestros métodos de pago se realizan a través de la empresa PayPal.me, empresa que sigue los más estrictos estándares de pago para garantizar una mayor estabilidad y seguridad de los datos de sus usuarios.",
  whyUseThePC: "¿Por qué hacer el sorteo por computadora?",
  whyUseThePCAnswer:
    "Bueno, realizar el sorteo por computadora facilita todo el proceso de conexión a Facebook y puede hacer que el sorteo sea aún más ágil y práctico. Instalar nuestra extensión oficial en Chrome, por ejemplo, puede acelerar enormemente la carga de comentarios para la publicación seleccionada. A esta instalación, no le podemos hacer en el móvil, así siendo solo es posible desde una PC .",
  postNotFound: "No se encontró mi publicación, ¿qué hago?",
  postNotFoundAnswer:
    "Ingrese su perfil de Instagram, haga clic en la publicación a dibujar y copie el enlace de esa pantalla. Después de eso, en el paso 2 (dos), donde selecciona la publicación para el sorteo, deslice la pantalla hasta el final y haga clic en el botón ubicado en la parte inferior de la pantalla junto a la oración:  'Desea pegar el enlace a tu publicación? ', ¡y ya está! Intentaremos encontrar su publicación perdida;)",
  stuckDraw: "Mi carga en la nube está bloqueada, ¿qué hago?",
  stuckDrawAnswer:
    "Envíe un mensaje a nuestro equipo de servicio. Estamos presentes en Instagram DM, chat en InstaSorteio chat y correo electrónico. Solo informa a @ Instagram y el enlace que solías regalar, así nuestro equipo se pondrá en contacto contigo para ayudarte.",
  paidForTheDrawQuestion: "Pagué mi sorteo hoy, ¿puedo sacar hasta qué día?",
  paidForTheDrawAnswer:
    "Tienes  7   consecutivos  días para realizar tu sorteo. Este período comienza desde el momento en que carga los comentarios en nuestro sitio web.",
  dueDateQuestion: "¿Para cuántos sorteos es válido Cloud Upload?",
  dueDateAnswer:
    "La carga en la nube es válida para  1 (un) dibujo . Dentro del plazo indicado en la respuesta anterior.",
  commentsCountQuestion:
    "¿Por qué la cantidad de comentarios en mi sorteo no se muestra correctamente?",
  commentsCountAnswer:
    "Algunos comentarios pueden ser vistos como spam por el propio Instagram y no se cargarán en nuestra plataforma.",
  clouldUploadWhileCommentingQuestion:
    "¿Puedo subir a la nube mientras la gente todavía está participando en el sorteo?",
  clouldUploadWhileCommentingAnswer:
    "No es el más adecuado, ya que los comentarios ingresados ​​después del inicio de la carga no participarán en el sorteo. Por ello, te recomendamos que dejes claro en tu publicación oficial la fecha y hora límite para participar en el sorteo.  ",
  refundQuestion: "¿Cómo solicitar un reembolso?",
  refundAnswer:
    "Póngase en contacto con nuestro servicio a través de  contato@instasorteio.com . Normalmente responderemos en un plazo de 48 horas para evaluar su caso y finalizar el contracargo en los próximos días.",
};

export default ComoFunciona;
