import Head from "next/head";
import Image from "next/image";
import { useState } from "react";
import { useRouter } from "next/router";
import useTranslation from "../hooks/useTranslation";
import SelectLanguageMenu from "@/components/SelectLanguageMenu";
import React from "react";

export default function Home() {
  const { t } = useTranslation();
  const [faqActive, setFaqActive] = useState(0);
  const [nossosSorteios, setNossosSorteios] = useState(false);
  const [openLang, setOpenLang] = useState(false);
  const router = useRouter();

  const InstaIcon = ({ className = "" }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 48 48"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.49 23.5a7.667 7.667 0 1115.333 0 7.667 7.667 0 11-15.333 0zm-4.145 0c0 6.523 5.288 11.81 11.81 11.81 6.524 0 11.812-5.287 11.812-11.81 0-6.523-5.288-11.81-11.811-11.81s-11.81 5.287-11.81 11.81zm21.329-12.279a2.761 2.761 0 005.522 0 2.76 2.76 0 00-2.761-2.759 2.76 2.76 0 00-2.761 2.759zM13.863 42.222c-2.243-.102-3.462-.476-4.272-.791-1.074-.418-1.84-.916-2.645-1.72-.805-.805-1.305-1.57-1.72-2.645-.317-.81-.69-2.028-.792-4.27C4.322 30.37 4.3 29.641 4.3 23.5c0-6.142.024-6.869.134-9.295.102-2.243.477-3.46.791-4.272.418-1.073.916-1.839 1.72-2.645.805-.805 1.57-1.304 2.646-1.72.81-.316 2.03-.69 4.272-.792 2.424-.112 3.152-.134 9.293-.134 6.142 0 6.869.024 9.295.134 2.242.102 3.46.477 4.271.791 1.074.417 1.84.916 2.646 1.72.804.805 1.302 1.573 1.72 2.646.316.81.69 2.03.792 4.272.111 2.426.134 3.153.134 9.295 0 6.14-.023 6.869-.134 9.295-.102 2.243-.478 3.461-.792 4.271-.418 1.074-.916 1.84-1.72 2.644-.805.805-1.572 1.303-2.646 1.72-.81.316-2.029.69-4.271.792-2.425.112-3.153.134-9.295.134-6.14 0-6.869-.022-9.293-.134zM13.673.638c-2.449.112-4.12.5-5.583 1.069A11.286 11.286 0 004.016 4.36C2.737 5.638 1.95 6.92 1.363 8.434.794 9.896.406 11.57.294 14.017.181 16.47.154 17.254.154 23.5s.027 7.03.14 9.483c.112 2.449.5 4.12 1.069 5.583.587 1.512 1.373 2.796 2.653 4.074 1.278 1.278 2.56 2.064 4.074 2.653 1.464.569 3.134.957 5.583 1.069 2.454.111 3.237.139 9.483.139 6.248 0 7.03-.026 9.483-.14 2.448-.11 4.12-.5 5.583-1.068 1.512-.589 2.794-1.375 4.074-2.653 1.278-1.278 2.064-2.562 2.653-4.074.569-1.462.959-3.135 1.068-5.583.112-2.455.138-3.237.138-9.483s-.026-7.03-.138-9.483c-.111-2.448-.5-4.12-1.068-5.583-.589-1.512-1.375-2.794-2.653-4.074-1.278-1.278-2.562-2.066-4.072-2.653C36.76 1.138 35.087.748 32.64.638 30.188.527 29.404.5 23.158.5c-6.248 0-7.03.026-9.485.14z" />
    </svg>
  );

  const FbIcon = ({ className = "" }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM17.1674 16.6263H20.0224L20.4003 12.9539H17.1676V10.7906C17.1676 9.97911 17.7039 9.78859 18.0852 9.78859H20.41V6.22055L17.2059 6.20714C13.6498 6.20714 12.8418 8.87077 12.8418 10.5718V12.9505H10.7845V16.6263H12.8418V27.0858H17.1674V16.6263Z"
        fill="white"
      />
    </svg>
  );

  const sorteios = [
    {
      id: 1,
      icon: "/images/instagram.svg",
      title: `${t("drawInstagram")}`,
      description: `${t("InstaDescriptionApps")}`,
      url: "https://instasorteio.com/pt/sorteio/instagram",
    },
    {
      id: 2,
      icon: "/images/dice.svg",
      title: `${t("dice")}`,
      description: `${t("DiceDescriptionApps")}`,
      url: "https://instasorteio.com/sorteio-de-dados",
    },
    {
      id: 3,
      icon: "/images/numbers.svg",
      title: `${t("numbers")}`,
      description: `${t("NumbersDescriptionApps")}`,
      url: "https://instasorteio.com/sorteio-de-numeros",
    },
    {
      id: 4,
      icon: "/images/names.svg",
      title: `${t("names")}`,
      description: `${t("NamesDescriptionApps")}`,
      url: "https://instasorteio.com/sorteio-de-nomes",
    },
    {
      id: 5,
      icon: "/images/wheel.png",
      title: `${t("wheel")}`,
      description: `${t("WheelDescriptionApps")}`,
      url: "https://instasorteio.com/sorteio-de-roleta",
    },
  ];

  const faqs = [
    /* {
      id: 1,
      question: `${t("ComoFuncionaTitle")}`,
      answer: `${t("ComoFuncionaSubtitle")}`,
    },
    {
      id: 2,
      question: `${t("howDoesTheClouldDrawWork")}`,
      answer: `${t("howDoesTheClouldDrawWorkAnswer")}`,
    },
    {
      id: 3,
      question: `${t("whyDoINeedToConnectMyFacebook")}`,
      answer: `${t("whyDoINeedToConnectMyFacebookAnswer")}`,
    },
    {
      id: 4,
      question: `${t("howDoIConnectMyFacebook")}`,
      answer: `${t("howDoIConnectMyFacebookAnswer")}`,
    }, */
    {
      id: 5,
      question: `${t("areThePaymentMethodsSafe")}`,
      answer: `${t("areThePaymentMethodsSafeAnswer")}`,
    },
    {
      id: 6,
      question: `${t("whyUseThePC")}`,
      answer: `${t("whyUseThePCAnswer")}`,
    },
    {
      id: 7,
      question: `${t("postNotFound")}`,
      answer: `${t("postNotFoundAnswer")}`,
    },
    {
      id: 8,
      question: `${t("stuckDraw")}`,
      answer: `${t("stuckDrawAnswer")}`,
    },
    {
      id: 9,
      question: `${t("paidForTheDrawQuestion")}`,
      answer: `${t("paidForTheDrawAnswer")}`,
    },
    {
      id: 10,
      question: `${t("dueDateQuestion")}`,
      answer: `${t("dueDateAnswer")}`,
    },
    {
      id: 11,
      question: `${t("commentsCountQuestion")}`,
      answer: `${t("commentsCountAnswer")}`,
    },
    {
      id: 12,
      question: `${t("clouldUploadWhileCommentingQuestion")}`,
      answer: `${t("clouldUploadWhileCommentingAnswer")}`,
    },
    {
      id: 13,
      question: `${t("refundQuestion")}`,
      answer: `${t("refundAnswer")}`,
    },
  ];
  const { locale } = useRouter();

  const navigateToInstagram = () => {
    // router.push("/pt/sorteio/instagram");
    router.push(locale + "/sorteio/instagram");
  };

  const scrollToTop = () => {
    const element = document.querySelector("#top");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Head>
        <title>Sorteio no Instagram - InstaSorteio</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div id="top" className="bg-violet-700">
        {nossosSorteios && (
          <dialog
            open
            className="absolute w-full h-full inset-0 bg-[#6D28D7] md:bg-violet-900 bg-opacity-90 z-50 flex justify-start items-start 
          sm:justify-center"
          >
            <div
              className="flex flex-col md:flex-row md:mt-24 md:rounded-2xl
            md:mr-0 md:pl-0 md:pr-10 md:py-0  
            md:h-[419px] md:w-[842px] w-full"
            >
              <div className="bg-[#6D28D7] flex justify-between px-8 md:mx-0 md:px-12 sm:rounded-l-2xl">
                <div className="flex flex-col my-4 sm:my-12 gap-4 text-white">
                  <div>
                    <Image
                      src="/images/config1.svg"
                      alt="close"
                      width={35}
                      height={35}
                    />
                  </div>
                  <div className="font-lexend text-2xl md:text-4xl text-wrap max-w-16">
                    {t("otherGiveaways")} <br />
                    <span className="text-[#FFD738]">
                      {t("otherGiveaways2")}
                    </span>
                  </div>
                  <div className="font-lato font-light text-base md:text-xl text-wrap max-w-40 md:max-w-[250px]">
                    {t("modalSubtitle")}
                  </div>
                </div>
                <div className="mt-4">
                  <button
                    onClick={() => setNossosSorteios(false)}
                    className="bg-white w-10 h-10 rounded-xl items-center justify-center flex font-lexend 
                  font-light text-sm md:hidden"
                  >
                    <Image
                      src="/images/xicon.svg"
                      alt="close"
                      width={20}
                      height={20}
                    />
                  </button>
                </div>
              </div>
              <div className="bg-white md:rounded-r-2xl md:flex md:flex-col md:justify-between md:px-2 md:py-20">
                <div className="hidden md:flex mt-[-60px] mb-16 mr-4 self-end">
                  <button
                    onClick={() => setNossosSorteios(false)}
                    className="bg-white w-10 h-10 rounded-xl items-center justify-center flex font-lexend 
                  font-light text-sm  border border-gray-400 hover:bg-violet-400"
                  >
                    <Image
                      src="/images/xicon.svg"
                      alt="close"
                      width={20}
                      height={20}
                    />
                  </button>
                </div>
                <div
                  className="flex flex-col  md:grid-cols-2 md:grid w-auto h-full  items-center 
              justify-center md:items-start py-4 sm:my-20 mx-16 md:my-0 md:mx-0 "
                >
                  <>
                    {sorteios.map((sorteio, index) => (
                      <>
                        <button
                          key={index}
                          onClick={() => open(sorteio.url, "_self")}
                          className="w-full mr-12 md:mr-0 py-6  md:py-4 rounded-lg hover:bg-violet-100 flex justify-between
                         h-auto border-b md:border-none md:border "
                        >
                          <div className="flex gap-2 ml-4">
                            <div
                              className="bg-violet-100 w-10 h-10 flex justify-center items-center 
                          rounded-lg"
                            >
                              <Image
                                src={sorteio.icon}
                                alt="icon"
                                width={20}
                                height={20}
                              />
                            </div>
                            <div className="font-lexend text-sm md:text-xs md:text-nowrap self-center text-violet-900">
                              {sorteio.title}
                            </div>
                          </div>
                          <div className="self-center pr-3">
                            <Image
                              src="/images/arrow.svg"
                              alt="instagram"
                              width={20}
                              height={20}
                            />
                          </div>
                        </button>
                      </>
                    ))}
                  </>
                </div>
              </div>
            </div>
          </dialog>
        )}
        <header className="bg-[#6D28D7] w-full h-auto flex items-center justify-between ">
          <div className="flex justify-between w-full 2xl:mx-[15vw]">
            <div className="pl-[40px] xs:pl-4 cursor-pointer sm:pl-[72px] 2xl:pl-0 pt-[23px] pb-[3px]">
              <Image
                src="/images/logo/PrincipalNegativa.svg"
                alt="Logo"
                width={156}
                height={64}
              />
            </div>

            <div className="flex items-center mt-[37px]">
              <div className="hidden text-white font-lexand font-medium text-sm lg:text-base md:flex gap-4 lg:gap-8 items-center mr-8 ">
                <div className="cursor-pointer hover:border-b border-yellow-500">
                  {t("init")}
                </div>
                <button
                  onClick={() => setNossosSorteios(!nossosSorteios)}
                  className="cursor-pointer hover:border-b border-yellow-500"
                >
                  {t("modalTitle")}
                </button>

                <button
                  onClick={() =>
                    open("https://instasorteio.com/como-funciona", "_self")
                  }
                  className="cursor-pointer hover:border-b border-yellow-500"
                >
                  {t("howItWorks")}
                </button>
              </div>
              {/* <button
              onClick={() => setOpenLang(!openLang)}
              className="w-16 cursor-pointer hover:bg-violet-900 rounded-lg flex h-[35px] 
            gap-2 mr-[40px] sm:mr-[72px] py-[6px] pl-[8px] items-center"
            >
              <div>
                <div className="flex gap-2">
                  <Image
                    src="/images/pt.svg"
                    alt="Logo"
                    width={32}
                    height={23}
                  />
                  <Image
                    src="/images/endIcon.svg"
                    alt="endIcon"
                    width={12}
                    height={20}
                  />
                </div>

                {openLang && (
                  <div className="mt-1 absolute bg-violet-900">
                    <div className="flex flex-col gap-2 w-full h-full justify-between">
                      <button className="hover:bg-violet-500 px-3 py-3 flex gap-2 items-center">
                        <Image
                          src="/images/pt.svg"
                          alt="Logo"
                          width={32}
                          height={23}
                        />
                        <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
                          <div className="bg-blue-500 w-2 h-2 rounded-full" />
                        </div>
                      </button>
                      <button
                        onClick={() =>
                          open("https://instasorteio.com/en", "_self")
                        }
                        className="hover:bg-violet-500 px-3 py-3 flex gap-2 items-center"
                      >
                        <Image
                          src="/images/english.svg"
                          alt="Logo"
                          width={32}
                          height={23}
                        />
                        <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center"></div>
                      </button>
                      <button
                        onClick={() =>
                          open("https://instasorteio.com/es", "_self")
                        }
                        className="hover:bg-violet-500 px-3 py-3 flex gap-2 items-center"
                      >
                        <Image
                          src="/images/espanha.svg"
                          alt="Logo"
                          width={32}
                          height={23}
                        />
                        <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center"></div>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </button> */}
              <SelectLanguageMenu />
            </div>
          </div>
        </header>

        {/* <Image
        src="/images/queridao.webp"
        alt="star1"
        width={860}
        height={860}
        className="hidden lg:flex lg:absolute z-[-1] ml-[420px] xl:mt-[200px] xl:w-[1000px] 
        xl:h-[1000px] 2xl:w-[1300px] 2xl:h-[1300px] xl:ml-[555px] 2xl:ml-[50vw] 2xl:mr-[20vw] 
        2xl:mt-[3vw] self-end"
      /> */}

        <section
          id="queridao"
          className="flex justify-start my-8 py-24 lg:py-48 xl:py-64 xs:pl-4 pl-10"
        >
          <div className="text-white max-w-[85vw] md:max-w-[560px] sm:px-[37px] 2xl:mx-[15vw] flex flex-col md:w-[540px] 2xl:w-[950px]">
            <h1 className="font-sans font-semibold xs:text-3xl text-5xl md:text-5xl xl:text-6xl leading-[50px]">
              {t("titleTeste")}{" "}
              <span className="text-[#ffd738]"> {t("titleSpan")}</span>
            </h1>
            <p className="font-lato mt-8 font-light md:w-[540px] ">
              <strong> {t("subtitleHome")}</strong> {t("subtitleHome2")}
            </p>
            <button
              onClick={navigateToInstagram}
              className="bg-[#ffd738] text-nowrap hover:bg-white mt-8 w-[430px] max-w-[85vw] md:w-[560px] h-[58px] md:h-[72px] self-start 
          rounded-xl text-[#5418A0] transition-all duration-700"
            >
              <div className="md:px-[104px] text-nowrap gap-[13px] xs:px-4 flex justify-center items-center font-lato">
                <div className="md:hidden">
                  <Image
                    src="/images/star1.svg"
                    alt="star1"
                    width={24}
                    height={24}
                  />
                </div>
                <div className="hidden md:flex">
                  <Image
                    src="/images/trophy1.svg"
                    alt="star1"
                    width={24}
                    height={24}
                  />
                </div>
                <p className="text-nowrap xs:text-[10px] flex-nowrap w-auto">
                  {t("instagramGiveaway")}
                </p>
              </div>
            </button>
            <button
              onClick={() => setNossosSorteios(!nossosSorteios)}
              className="bg-[#9655f870] hover:bg-white hover:text-[#5418A0] mt-6 w-[430px] max-w-[85vw] md:w-[560px] h-[58px] 
          md:h-[72px] self-start rounded-xl transition-all duration-700"
            >
              <div className="px-[104px] gap-[13px] flex justify-center font-lato">
                <p className="text-nowrap xs:text-[10px]">
                  {t("otherGiveawaysButton")}
                </p>
              </div>
            </button>
          </div>
        </section>

        <section
          /* id="louro" */
          className="flex py-24 lg:py-72 bg-mask justify-center w-screen  sm:px-10  opacity-90"
        >
          <div className="text-white flex flex-col items-center gap-8 justify-center py-8 w-full">
            <p className="font-lato font-regular">{t("trustTitle")}</p>
            <h2
              className="font-lexend font-bold text-center text-2xl md:text-3xl md:mx-[120px] lg:mx-[180px] lg:text-5xl 
          mx-[65px] leading-8"
            >
              {t("trustH2")}
            </h2>
            <p className="font-lato max-w-[85vw] font-regular text-center w-[450px] mx-12">
              {t("trustBody")}
              <strong>{t("trustBody2")}</strong> {t("trustBody3")}
            </p>
            {/* <div className="bg-louro bg-cover bg-no-repeat absolute w-screen h-48 flex mx-10 mt-24" /> */}
            <button
              onClick={navigateToInstagram}
              className="bg-white hover:bg-[#6D28D7]  text-[#5418A0] hover:text-white mt-6 w-[430px] max-w-[85vw] h-[58px] self-center rounded-xl transition-all duration-700"
            >
              <div className="px-[104px] gap-[13px] flex justify-center font-lato">
                <InstaIcon className="h-6 w-6 hover:text-white" />
                <p className="text-nowrap">{t("instagramGiveaway")}</p>
              </div>
            </button>
          </div>
        </section>

        <section className="flex min-w-full bg-white h-full justify-start py-8">
          <div className="text-black flex flex-col items-center gap-8 justify-center h-full py-8 w-full">
            <p className="font-lato font-light text-sm">{t("modalTitle")}</p>
            <h2 className="font-lexend font-medium text-center text-2xl md:text-4xl mx-[65px] text-[#6d28d7] leading-8">
              {t("otherDrawsH2")}
            </h2>
            <p className="font-lato font-light text-center mx-12">
              {t("otherDrawsBody")}
              <br />
              {t("otherDrawsBody2")} <strong>{t("otherDrawsBody3")}</strong>
            </p>
          </div>
        </section>

        <section className="flex flex-col md:grid-cols-2 md:grid lg:px-12 xl:px-24 min-w-full bg-white h-full justify-start pb-12">
          {sorteios.map((sorteio) => (
            <div
              key={sorteio.id}
              className="text-black  flex flex-col items-center gap-8 justify-center h-full  w-full"
            >
              <button
                onClick={() => open(sorteio.url, "_self")}
                className="w-[453px] h-[264px]  hover:bg-violet-200 transition-all duration-500 cursor-pointer rounded-xl flex  flex-col"
              >
                <div className="mt-[41px] w-[371px] mx-[41px] xs:w-72 xs:pl-3 xs:justify-center xs:gap-6 xs:mx-16  flex items-center justify-between ">
                  <div className=" items-center flex w-auto">
                    <div className="bg-gray-100 w-20 h-20 rounded-xl flex justify-center items-center">
                      <Image
                        src={sorteio.icon}
                        alt="icon"
                        width={50}
                        height={50}
                      />
                    </div>
                    <h3 className="ml-6 font-lexend text-lg text-wrap xs:text-sm  text-start text-[20px] text-[#4e3d66]">
                      {sorteio.title}
                    </h3>
                  </div>
                  <Image
                    src="/images/arrow.svg"
                    alt="arrow"
                    width={20}
                    height={30}
                  />
                </div>
                <p className="mx-[41px] xs:mx-16 xs:pl-3 xs:w-72 text-start flex font-lato text-md font-light leading-[26px] pt-4">
                  {sorteio.description}
                </p>
              </button>
            </div>
          ))}
        </section>

        <section className="flex min-w-full bg-gray-100 h-full justify-start py-40">
          <div className="text-black bg-white flex flex-col items-center gap-8 justify-center h-full py-8 w-full">
            <div className="bg-violet-700 w-28 h-1 rounded-lg mt-[-32px]" />
            <h2 className="font-lexend font-medium text-center text-2xl md:text-4xl mx-[65px] text-[#6d28d7] leading-8">
              {t("howItWorks")}
            </h2>
            <p className="font-lato font-regular text-[#4e3d66] leading-7 text-center mx-12">
              {t("howDoesItWorkBody")}
            </p>
            <div className="bg-violet-700 w-[436px] xs:w-64 h-[112px] flex rounded-xl mb-[-82px]">
              <div className="flex">
                <div className="bg-violet-500 h-14 w-14 rounded-xl ml-7 mt-7 flex items-center justify-center">
                  <FbIcon className="text-[#6d28d7]" />
                </div>
              </div>
              <p className="p-7 leading-6 text-sm font-lato xs:text-xs font-light text-white">
                {t("connectWithFb")}{" "}
                <strong className="text-[#ffd738]">
                  {t("connectWithFb2")}
                </strong>{" "}
                {t("connectWithFb3")}
              </p>
            </div>
          </div>
        </section>

        <section className="flex min-w-full bg-gray-100 h-full justify-start">
          <div className="text-black bg-white flex flex-col  items-center gap-8 justify-center h-full py-10 w-full">
            <h2 className="font-lexend font-medium text-center text-2xl md:text-3xl lg:text-4xl  text-[#6d28d7] leading-8">
              {t("faq")}
            </h2>
            {faqs.map((faq) => (
              <React.Fragment key={faq.id}>
                <div
                  key={faq.id}
                  className="w-[430px] max-w-[85vw] lg:w-[650px] flex justify-between gap-24 items-center"
                >
                  <strong className="font-lato max-w-72 lg:max-w-[420px] font-regular text-[#4e3d66] text-start ">
                    {faq.question}
                  </strong>
                  <div>
                    <button
                      onClick={() => {
                        if (faqActive === faq.id) {
                          setFaqActive(0);
                        } else {
                          setFaqActive(faq.id);
                        }
                      }}
                      className={`${
                        faqActive === faq.id && "rotate-180"
                      } w-10 h-10 cursor-pointer rounded-full shadow-sm transition-all duration-700 shadow-gray-500 flex items-center justify-center`}
                    >
                      <Image
                        src="/images/arrowDown.svg"
                        alt="arrow"
                        width={25}
                        height={39}
                      />
                    </button>
                  </div>
                </div>
                {faq.id === faqActive && (
                  <p className="font-lato font-regular flex self-center w-[430px] max-w-[85vw] lg:w-[650px] text-[#4e3d66] text-justify text-md  leading-7">
                    {faq.answer}
                  </p>
                )}
                <line className="w-[430px] max-w-[85vw] lg:w-[650px] h-[1px] bg-violet-700 opacity-30" />
              </React.Fragment>
            ))}
          </div>
        </section>

        <section className="flex min-w-full h-full justify-start bg-gray-100 py-20 ">
          <div className="text-white bg-gradient-radial from-violet-500 to-violet-900 opacity-90 py-32 gap-6 flex flex-col items-center justify-center relative h-full w-full">
            <h2 className="font-lexend font-bold text-center text-2xl md:text-3xl lg:text-4xl mx-[65px] leading-8">
              {t("startNowH2")}
            </h2>
            <p className="font-lato font-light max-w-[80vw] text-center mx-12">
              {t("startNowBody")}{" "}
              <strong className="text-[#ffd737]">{t("startNowBody2")}</strong>
            </p>
            <button
              onClick={navigateToInstagram}
              className="bg-white hover:bg-[#6D28D7]  text-[#5418A0] hover:text-white mt-6 w-[430px] max-w-[85vw] h-[58px] self-center rounded-xl transition-all duration-700"
            >
              <div className="px-[104px] gap-[13px] flex justify-center font-lato">
                <InstaIcon className="h-6 w-6 hover:text-white" />
                <p className="text-nowrap">{t("instagramGiveaway")}</p>
              </div>
            </button>
          </div>
        </section>

        <section className="flex min-w-full bg-gray-100 h-full w-full justify-start items-start">
          <div className="w-full justify-start font-lato flex flex-col lg:flex-row  ">
            <div className="w-full lg:hidden  font-lato gap-16 flex flex-col mr-10 py-12">
              <div className="flex  font-medium flex-col justify-center mx-12 xs:mx-4 gap-5">
                <h3 className=" self-start text-center text-base text-[#6d28d7]">
                  Sessões
                </h3>

                <line className="h-[1px] w-[420px] px-20 bg-violet-200" />

                <div className="mt-4 font-medium text-nowrap text-gray-700 flex justify-between w-full pr-12">
                  <div className="flex flex-col w-full pr-28 items-start gap-3 justify-start">
                    <button onClick={scrollToTop}>{t("init")}</button>
                    <button onClick={() => setNossosSorteios(true)}>
                      {t("otherGiveaways")}
                    </button>
                  </div>

                  <div className="flex flex-col w-full   items-start gap-3 justify-start pr-12">
                    <button
                      onClick={() =>
                        open("https://instasorteio.com/como-funciona", "_self")
                      }
                    >
                      {t("howItWorks")}
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex flex-col justify-center mx-12 xs:mx-4 gap-5">
                <h3 className="font-medium self-start text-center text-base text-[#6d28d7]">
                  {t("giveaways")}
                </h3>

                <line className="h-[1px] w-[420px] px-20 bg-violet-200" />

                <div className="mt-4 font-medium text-nowrap gap-6 text-gray-700 flex justify-between w-full pr-12">
                  <div className="flex flex-col w-full items-start gap-3 justify-start">
                    <button onClick={navigateToInstagram}>Instagram</button>
                    <button
                      onClick={() =>
                        open(
                          "https://instasorteio.com/sorteio-de-numeros",
                          "_self"
                        )
                      }
                    >
                      {t("numbers")}
                    </button>
                    <button
                      onClick={() =>
                        open(
                          "https://instasorteio.com/sorteio-de-roleta",
                          "_self"
                        )
                      }
                    >
                      {t("wheel")}
                    </button>
                  </div>

                  <div className="flex flex-col w-full  items-start gap-3 justify-start pr-12">
                    <button
                      onClick={() =>
                        open(
                          "https://instasorteio.com/sorteio-de-dados",
                          "_self"
                        )
                      }
                    >
                      {t("dice")}
                    </button>
                    <button
                      onClick={() =>
                        open(
                          "https://instasorteio.com/sorteio-de-nomes",
                          "_self"
                        )
                      }
                    >
                      {t("names")}
                    </button>
                    <button
                      onClick={() => open("https://sorteio.com/", "_self")}
                    >
                      Sorteio.com
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex flex-col justify-start items-start gap-5">
                <h3 className="font-medium self-start text-center mx-12 xs:mx-4 text-base text-[#6d28d7]">
                  {t("help")}
                </h3>

                <line className="h-[1px] w-[420px] px-20 bg-violet-200 mx-12 xs:mx-4 " />

                <div className="font-medium text-nowrap  text-gray-700 mx-12 xs:mx-4 flex justify-between w-full pr-12">
                  <div className="flex flex-col w-full items-start gap-3 justify-start">
                    <button
                      onClick={() =>
                        open("mailto:contato@instasorteio.com", "_self")
                      }
                    >
                      {t("contactUs")}
                    </button>
                    <button
                      onClick={() =>
                        open(
                          "https://instasorteio.com/politica-de-privacidade",
                          "_self"
                        )
                      }
                    >
                      {t("privacyPolicy")}
                    </button>
                    <button
                      onClick={() =>
                        open("https://instasorteio.com/termos-de-uso", "_self")
                      }
                    >
                      {t("termsOfUse")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex pb-10 flex-col gap-2 w-full font-light text-sm justify-center items-center lg:justify-start lg:items-start ">
              <div className="flex flex-col text-start mx-20 items-center justify-center lg:items-start lg:justify-start">
                <Image
                  src="/images/logo/PrincipalPositiva.svg"
                  alt="Logo"
                  width={156}
                  height={64}
                />
              </div>{" "}
              <div className="flex flex-col gap-2 w-full font-light text-sm items-center justify-center lg:items-start lg:justify-start">
                <div className="flex flex-col justify-start text-start mx-20 ">
                  {t("copyright")}
                </div>
                <div className="flex flex-col text-nowrap justify-start text-start mx-20 ">
                  {t("copyright2")}
                </div>
              </div>
              <div className="flex flex-col gap-2 w-full font-light text-sm items-center justify-center lg:items-start lg:justify-start pt-6">
                <div className="flex justify-between w-[270px]   lg:ml-10 px-1 py-1 bg-white rounded-lg ">
                  <div className="pl-2 py-2">
                    <Image
                      src="/images/polvo.svg"
                      alt="Logo"
                      width={30}
                      height={8}
                    />
                  </div>
                  <div>
                    Siga a gente! <br /> @instasorteioo
                  </div>
                  <button
                    onClick={() =>
                      open("https://www.instagram.com/instasorteioo/", "_blank")
                    }
                    className="bg-blue-700 cursor-pointer hover:opacity-70 text-white font-medium px-4 my-3 mr-2  rounded-md items-center justify-center flex"
                  >
                    Seguir
                  </button>
                </div>
              </div>
            </div>

            <div className="w-full hidden lg:flex font-lato gap-12 h-full mr-16 pb-12">
              <div className="flex font-medium flex-col justify-start items-start gap-5">
                <h3 className=" self-start text-start text-base text-[#6d28d7]">
                  Sessões
                </h3>
                <line className="h-[1px] px-20 bg-violet-200" />
                <div className="font-medium text-gray-700 flex justify-start items-start w-full gap-2 flex-col  ">
                  <button onClick={scrollToTop}>{t("init")}</button>

                  <button onClick={() => setNossosSorteios(true)}>
                    {t("otherGiveaways")}
                  </button>
                  <button
                    onClick={() =>
                      open("https://instasorteio.com/como-funciona", "_self")
                    }
                  >
                    {t("howItWorks")}
                  </button>
                </div>
              </div>
              <div className="flex font-medium flex-col justify-start items-start gap-5">
                <h3 className="self-start text-start text-base text-[#6d28d7]">
                  {t("giveaways")}
                </h3>
                <line className="h-[1px] px-20 bg-violet-200" />
                <div className="font-medium text-gray-700 flex justify-start items-start w-full gap-2 flex-col  ">
                  <button onClick={navigateToInstagram}>Instagram</button>
                  <button
                    onClick={() =>
                      open(
                        "https://instasorteio.com/sorteio-de-numeros",
                        "_self"
                      )
                    }
                  >
                    {t("numbers")}
                  </button>
                  <button
                    onClick={() =>
                      open("https://instasorteio.com/sorteio-de-dados", "_self")
                    }
                  >
                    {t("dice")}
                  </button>
                  <button
                    onClick={() =>
                      open("https://instasorteio.com/sorteio-de-nomes", "_self")
                    }
                  >
                    {t("names")}
                  </button>
                  <button
                    onClick={() =>
                      open(
                        "https://instasorteio.com/sorteio-de-roleta",
                        "_self"
                      )
                    }
                  >
                    {t("wheel")}
                  </button>
                  <button onClick={() => open("https://sorteio.com/", "_self")}>
                    Sorteio.com
                  </button>
                </div>
              </div>
              <div className="flex font-medium flex-col justify-start gap-5">
                <h3 className=" self-start text-start text-base text-[#6d28d7]">
                  Sorteios
                </h3>
                <line className="h-[1px] px-20 bg-violet-200" />
                <div className="font-medium text-gray-700 text-start flex justify-start items-start w-full gap-2 flex-col  ">
                  <button
                    onClick={() =>
                      open("mailto:contato@instasorteio.com", "_self")
                    }
                  >
                    {t("contactUs")}
                  </button>
                  <button
                    onClick={() =>
                      open("https://instasorteio.com/termos-de-uso", "_self")
                    }
                  >
                    {t("termsOfUse")}
                  </button>
                  <button
                    onClick={() =>
                      open(
                        "https://instasorteio.com/politica-de-privacidade",
                        "_self"
                      )
                    }
                    className="text-nowrap"
                  >
                    {t("privacyPolicy")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
