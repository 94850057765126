const NumbersDraw = {
  subtitleNumbers:
    'Sorteie um ou mais números dentro do intervalo escolhido 😆',
  numbersToBeDrawn: 'Quantidade de números a serem sorteados',
  pickNumbersBetween: 'Sortear números entre (mín)',
  maxNumber: 'e (máx)',
  allowDuplicates: 'Não permitir números repetidos',
  result: 'Resultado',
  minMaxError:
    'O valor mínimos deve ser maior ou igual ao valor máximo do intervalo',
  numbersToBeDrawnShort: 'Qnt. nº sorteados',
  range: 'Intervalo',
  cleanLog: 'Limpar Histórico',
  draw: 'Sortear',
  drawAgain: 'Sortear novamente',
};

export default NumbersDraw;
