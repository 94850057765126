const ComoFunciona = {
  ComoFuncionaTitle: "How does the free giveaway work?",
  ComoFuncionaSubtitle:
    "The free instagram giveaway works for posts that have up to 300 comments.",
  ComoFuncionaStep1: "Enter your Instagram user @.",
  ComoFuncionaStep2: "Select your post and configure it.",
  ComoFuncionaStep3:
    "Log in with Facebook and allow reading comments from your Instagram profile.",
  faq: "FAQ",
  comoFuncionaAnswer:
    "The free draw works for draws that have less than 300 comments and the connection between Facebook and Instagram is mandatory. They are simple to capture and upload comments. If your post has more than this amount, you must use the Cloud GIveaway.",
  howDoesTheClouldDrawWork: "How does the Cloud Giveaway work?",
  howDoesTheClouldDrawWorkAnswer:
    "Cloud upload is used for posts that have more than 300 comments and have a symbolic cost. Because, these types of draws have a greater complexity and need more care to capture and load all comments. Also, our tool uploads comments to the cloud, so there is no risk of losing comments and the best part: Using cloud upload you don't need to have the connection between Facebook and Instagram. Extra: You can have less than 300 comments and use the Cloud Giveaway to facilitate the entire drawing process.",
  whyDoINeedToConnectMyFacebook:
    "Why do I need to connect Facebook to Instagram?",
  whyDoINeedToConnectMyFacebookAnswer:
    "This process is necessary for our tool to connect with Facebook, which also owns Instagram, to be able to identify all comments. Every connection is made within Facebook itself and follows the network's privacy standards.",
  howDoIConnectMyFacebook: "How do I connect Facebook to Instagram?",
  howDoIConnectMyFacebookAnswer:
    "1 - Access Facebook pages and create a page (or access your already created page); 2 - Click Page Setup and then Instagram; 3 - Now just fill in your Instagram login and password… that's it. Enter instasorteio.com.br and make your giveaway!",
  areThePaymentMethodsSafe: "Are payment methods safe?",
  areThePaymentMethodsSafeAnswer:
    "Users who opt for cloud upload can complete the transaction with peace of mind, as payment methods: PIX and credit card are extremely safe.",
  whyUseThePC: "Why should I give preference to use the computer?",
  whyUseThePCAnswer:
    "Performing the giveaway by computer facilitates the entire process of connecting to Facebook and can make the draw even more agile and practical.",
  postNotFound: "My post was not found, what do I do?",
  postNotFoundAnswer:
    "Click on the raffle image and copy the post link (in the instagram feed). Now, put on the screen bar select the post on the website.",
  stuckDraw: "My cloud upload is stuck, what do I do?",
  stuckDrawAnswer:
    "Send a message to our support at Instagram DM and inform the @ Instagram and the link you used to give away, so our team will contact you to help you.",
  paidForTheDrawQuestion:
    "I paid my giveaway today, can I draw until which day?",
  paidForTheDrawAnswer:
    "You have 7 calendar days to make your draw. This period starts from the moment you upload the comments on our website.",
  dueDateQuestion: "How many draws is Cloud Upload valid for?",
  dueDateAnswer:
    "Cloud Upload is valid for 1 (one) drawing. Within the deadline indicated in the previous answer.",
  commentsCountQuestion: "Why isn't my post comment number showing correctly?",
  commentsCountAnswer:
    "Some comments may be viewed as spam by Instagram itself.",
  clouldUploadWhileCommentingQuestion:
    "Can I cloud upload while people are still participating in the draw?",
  clouldUploadWhileCommentingAnswer:
    "It's not the most suitable, as people can comment on your raffle after uploading comments and thus stay out. So, say in your official post how long these people can participate and upload after finalizing the comments.",
  refundQuestion: "How to request a refund?",
  refundAnswer:
    "Contact our service via contato@instasorteio.com. We will normally respond within 48 hours to assess your case and finalize the chargeback in the coming days.",
};

export default ComoFunciona;
