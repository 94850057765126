const DiceDraw = {
  diceDraw: 'Rolar Dados',
  selectTheAmountOfDataBelow: 'Selecione a quantidade de dados abaixo :',
  throw: 'jogar',
  rollDice: 'Rolar Dado',
  rollAllDice: 'Rolar Dados',
  numbers: 'Números',
  total: 'Total',
  diceThrown: 'Dados jogados',
  clearLog: 'Limpar Histórico',
};

export default DiceDraw;
