const ComoFunciona = {
  ComoFuncionaTitle: "Como funciona o sorteio gratuito?",
  ComoFuncionaSubtitle:
    "O sorteio grátis funciona para os sorteios que tenham até 300 comentários.",
  ComoFuncionaStep1: "Informe seu @ do usuário do Instagram.",
  ComoFuncionaStep2: "Selecione seu post e o configure.",
  ComoFuncionaStep3:
    "Faça login com Facebook e permita a leitura dos comentários do seu perfil do Instagram.",
  faq: "Perguntas Frequentes",
  comoFuncionaAnswer:
    "O sorteio grátis funciona para os sorteios que tenham menos de 300 comentários e tem como obrigatoriedade a conexão entre Facebook e Instagram, só assim conseguimos carregar todos os comentários sem cobrar taxas. Caso o seu sorteio tenha mais de 300 comentários, será necessário fazer o sorteio em nuvem.",
  howDoesTheClouldDrawWork: "Como funciona o Sorteio em Nuvem?",
  howDoesTheClouldDrawWorkAnswer:
    "O carregamento em nuvem é utilizado para sorteios que tenham mais de 300 comentários e tem um custo simbólico por serem mais complexos e exigirem maior infraestrutura. Com esse tipo de sorteio seus comentários serão carregados diretamente na nuvem, o que te permite prosseguir o carregamento mesmo sem estar com a aba do InstaSorteio aberta em seu navegador. E tem mais, utilizando o carregamento em nuvem você não precisa fazer conexão entre Facebook e Instagram.",
  whyDoINeedToConnectMyFacebook:
    "Por que eu preciso conectar uma página do Facebook ao Instagram?",
  whyDoINeedToConnectMyFacebookAnswer:
    "Esse processo é necessário porque só assim conseguimos carregar 100% dos comentários da publicação de forma totalmente gratuita para nossos usuários. Além disso, essa é uma das maneiras mais seguras de ter acesso à uma publicação no Instagram, já que a conexão é feita dentro do próprio Facebook e segue os padrões de privacidade da rede.",
  howDoIConnectMyFacebook: "Como eu conecto o Facebook ao Instagram?",
  howDoIConnectMyFacebookAnswer:
    "1 - Clique no botão páginas, no Facebook → crie uma página, ou acesse uma já criada; 2 - Clique em Configuração de Página e na sequência em Instagram; 3 - Agora é só preencher com login e senha do seu Instagram… prontinho. Entre no instasorteio.com.br e faça seu sorteio!",
  areThePaymentMethodsSafe: "Os métodos de pagamento são seguros?",
  areThePaymentMethodsSafeAnswer:
    "Os usuários que optarem pelo carregamento em nuvem podem realizar a transação com tranquilidade. Nossos métodos de pagamento são feitos por meio da empresa pagar.me, companhia que segue os mais rigorosos padrões de pagamentos para garantir mais estabilidade e segurança dos dados de seus usuários.",
  whyUseThePC: "Por que fazer sorteio pelo computador?",
  whyUseThePCAnswer:
    "Realizar o sorteio pelo computador facilita todo o processo de conexão com o Facebook e pode deixar o sorteio ainda mais ágil e prático. Instalar nossa extensão oficial no Chrome, por exemplo, pode acelerar bastante o carregamento dos comentários da publicação selecionada. Essa instalação só é possível por meio de um desktop.",
  postNotFound: "Meu post não foi localizado, o que eu faço?",
  postNotFoundAnswer:
    "Entre no seu perfil do Instagram, clique na publicação a ser sorteada e copie o link dessa tela. Depois disso no passo 2 (dois), onde você seleciona a publicação para o sorteio, deslize a tela até o final e clique no botão localizado na parte inferior da tela ao lado da frase: 'Quer colar o link do seu post?', e pronto! Iremos tentar encontrar a sua publicação perdida ;)",
  stuckDraw: "Meu carregamento em nuvem está travado, o que eu faço?",
  stuckDrawAnswer:
    "Envie uma mensagem para a nossa equipe de atendimento. Estamos presentes na DM do Instagram, chat no chat do InstaSorteio e e-mail. Basta informar o @ do Instagram e o link que você utilizou para sortear, assim nossa equipe entrará em contato para te auxiliar.",
  paidForTheDrawQuestion:
    "Paguei meu sorteio hoje, posso sortear até qual dia?",
  paidForTheDrawAnswer:
    "Você tem 7 dias corridos para realizar o seu sorteio. Esse prazo é contado a partir do momento que você realizou o carregamento dos comentários em nosso site.",
  dueDateQuestion: "O Carregamento em Nuvem é válido para quantos sorteios?",
  dueDateAnswer:
    "O Carregamento em Nuvem é válido para realizar 1 (um) sorteio. Dentro do prazo sinalizado na resposta anterior.",
  commentsCountQuestion:
    "Por que o número de comentários do meu sorteio não está aparecendo corretamente?",
  commentsCountAnswer:
    "Alguns comentários podem ser visualizados como spam pelo próprio Instagram e não serão carregados em nossa plataforma.",
  clouldUploadWhileCommentingQuestion:
    "Posso fazer o carregamento em nuvem enquanto as pessoas ainda estão participando do sorteio?",
  clouldUploadWhileCommentingAnswer:
    "Não é o mais indicado, já que os comentários inseridos depois do início do carregamento não participarão do sorteio. Sendo assim, recomendamos que você  deixe claro em seu post oficial qual a data e horário final para participação do sorteio.",
  refundQuestion: "Como pedir o reembolso?",
  refundAnswer:
    "Entre em contato com o nosso atendimento via contato@instasorteio.com. Normalmente, responderemos em até 48h para avaliar seu caso e finalizar o estorno nos próximos dias.",
};

export default ComoFunciona;
