import Names from './Names';
import DiceDraw from './DiceDraw';

import Menu from './Menu';
import NumbersDraw from './NumbersDraw';
import Roulette from './Roulette';
import Home from './Home';
import Influencers from './Influencers';
import ComoFunciona from './ComoFunciona';

export const LanguageEs = {
  es: {
    ...Menu,
    ...DiceDraw,
    ...NumbersDraw,
    ...Names,
    ...Roulette,
    ...Home,
    ...Influencers,
    ...ComoFunciona,
  },
};
