const Menu = {
  home: "Comienzo",
  ourApps: "otros sorteos",
  howItWorks: "Como Funciona",
  digitalInfluencer: "Influencer digital",
  otherGiveaways: "otros sorteos",
  theApp: "La aplicación",
  influencers: "Influencers",
  drawLots: "Realizar sorteo",
  contactUs: "Hable con nosotros",
  termsOfUse: "Terminos de uso",
  privacyPolicy: "Política de privacidad",
  language: "idiomam",
  copyrights: "Copyright 2021. Todos los derechos reservados",
  // Nossos Apps
  drawInstagram: "Sorteo en Instagram",
  drawFacebook: "Sorteo no Facebook",
  drawNumbers: "Sorteo en Números",
  drawNames: "Sorteo de nombre",
  drawDice: "Extracción de datos",
  drawRoulette: "Rifa de ruleta",
  drawTwitter: "Sorteo de Twitter ",
  drawYoutube: "Sorteo do Youtube",
  drawTikTok: "Sorteo do Tiktok",
};

export default Menu;
