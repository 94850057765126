const Names = {
  namesDraw: 'Random Names',
  writeDownATitle: 'Choose a title',
  names: 'Names',
  namesOnePerLine: 'Names - Write down one name per line',
  insertOneNamePerLine: 'Enter one name per line',
  startConfig: 'Start configurations',
  substitutes: 'Alternates',
  settings: 'Settings',
  filterDoubled: 'Filter out duplicates',
  filterDoubledYes: 'Yes',
  filterDoubledNo: 'No',
  participants: 'Participants',
  back: 'Back',
  winners: 'Winners',
};

export default Names;
