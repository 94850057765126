const Home = {
  language: "pt",
  init: "Home",
  howItWorks: "Como Funciona",
  titleTeste: `Hacer sorteos creativo en forma`,
  titleSpan: `sin complicaciones`,
  subtitleHome: "Facilitamos tu sorteo",
  subtitleHome2:
    "a través de una plataforma sencilla. ¡Sube miles de comentarios y haz grandes sorteos!",
  instagramGiveaway: "Sorteo en Instagram",
  otherGiveaways: "Nuestros",
  otherGiveaways2: "Sorteos",
  trustTitle: "CONFIANZA EN LOS SORTEOS",

  trustH2: "Más de 1 millones de comentarios subidos a Instagram",
  trustBody: "Nuestro algoritmo está hecho para ",
  trustBody2: "cargar todos los comentarios",
  trustBody3:
    "  de su publicación y obtener un ganador de una manera totalmente aleatoria y confiable.",
  otherDrawsH2: "Elige un tipo de dibujo",
  otherDrawsBody:
    "¡Tenemos la solución para todo tipo de sorteos en un solo lugar!",
  otherDrawsBody2: " Elija cuál se adapta mejor a su propósito y disfrute de",
  otherDrawsBody3: " todos los formatos de InstaSorteio.",
  startNowH2: "¡Empezar ahora!",
  startNowBody: "¡Sube miles de comentarios",
  startNowBody2: "y haz grandes sorteos!",
  lastCardGrid: " ¡Todo es muy seguro   y gratis! ",
  howDoesItWorkBody:
    /* "Instasorteio es completamente gratuito para publicaciones con  hasta 300 comments subidos. Para subir más comentarios solo paga  R$ 59,99  por dibujo * valor válido para publicaciones con hasta 60 mil comentarios, ver valores para dibujos más grandes.  ** El sorteo pagado se puede retener dentro de los 7 días .", */
    "Para cargar sus comentarios, pague solo R$59,99 por sorteo *valor válido para publicaciones de hasta 60,000 comentarios, consulte precios para sorteos mayores. **El sorteo pagado puede realizarse en hasta 7 días.",
  connectWithFb: " Conéctate con tu Facebook y tu tiempo de carga.",
  connectWithFb2: " reduce hasta un 40%",
  connectWithFb3: " tu tiempo de carga.",
  influencersSubtitle:
    "  Espiar solo a aquellos que también usan nuestra plataforma",
  influencersSubtitle2:
    "Más de 10 millones de personas utilizan nuestra plataforma.",
  understandTheVip: "Entender el sorteo pagado",
  understandTheVipBody:
    "Mira el video hasta el final para comprender cada detalle de esta característica que te brinda ventajas.",
  meetOurApp: " Conozca la aplicación",
  meetOurAppBody:
    "La facilidad de hacer sorteos ahora en la palma de su mano. Descarga desde el botón de abajo.",
  copyright: "Copyright 2021.",
  copyright2: "Todos los derechos reservados.",
  followUs: "Síguenos!",
  giveaways: "Lotería de carreras",
  numbers: "Sorteo de Números",
  names: "Sorteo de Nombres",
  wheel: "Sorteo de Ruleta",
  flag: "EsFlag",
  follow: "Seguir",
  help: "Ayudar",
  sections: "Sesiones",

  //
  dice: " Sorteo de Datos",
  InstaDescriptionApps:
    "¡Selecciona una publicación en tu perfil de Instagram y rifa comentarios fácilmente!",
  FbDescriptionApps:
    "Al igual que ocurre con Instagram, el sorteo de Facebook se realiza de forma dinámica, segura y muy práctica.",
  DiceDescriptionApps:
    "Seleccione de uno a seis dados para jugar virtualmente. La herramienta incluso enumera los números extraídos para usted.",
  NumbersDescriptionApps:
    "Elija el rango de números, el número de sorteos y si desea que se saque el mismo número más de una vez.",
  NamesDescriptionApps:
    "Agrega los nombres de los involucrados, decide qué configuraciones se aplicarán y listo, solo dibuja.",
  WheelDescriptionApps:
    "Agrega los artículos que quieras regalar, personaliza y presenta al ganador de una manera divertida.",
  TikTokDescriptionApps:
    "Ahora nuestra herramienta también está disponible para la aplicación más descargada en todo el mundo.",
  TwitterDescriptionApps:
    "También es posible regalar publicaciones en Twitter, una de las redes sociales más comprometidas del mundo.",
  YouTubeDescriptionApps:
    "Hacer sorteos en tus videos de Youtube es una gran noticia y ahora, con nuestra plataforma, será posible.",
  modalTitle: "Nuestro sorteos",
  otherGiveawaysButton: "Nuestro sorteos",
  modalSubtitle: "Seleccione una opción  para iniciar un sorteo.",
  soon: "Pronto",
};

export default Home;
