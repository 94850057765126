const Menu = {
  home: "Inicio",
  ourApps: "Outros sorteios",
  howItWorks: "Como Funciona",
  digitalInfluencer: "Digital Influencer",
  otherGiveaways: "Outros sorteios",
  theApp: "O aplicativo",
  influencers: "Influencers",
  drawLots: "Realizar Sorteio",
  contactUs: "Fale Conosco",
  termsOfUse: "Termos de Uso",
  privacyPolicy: "Política de Privacidade",
  language: "Linguagem",
  copyrights: "Copyright 2021. Todos os Direitos Reservados",
  // Nossos Apps
  drawInstagram: "Sorteio no Instagram",
  drawFacebook: "Sorteio no Facebook",
  drawNumbers: "Sorteio de Números",
  drawNames: "Sorteio de Nomes",
  drawDice: "Sorteio de Dados",
  drawRoulette: "Sorteio de Roleta",
  drawTwitter: "Sorteio do Twitter",
  drawYoutube: "Sorteio do Youtube",
  drawTikTok: "Sorteio do Tiktok",
};

export default Menu;
