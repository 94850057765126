const Roulette = {
  roulette: 'Rifa de ruleta',
  rouletteSettings: 'Configuremos tu sorteo',
  winner: 'Ganador',
  holdDraw: 'Realizar sorteo',
  informNames: 'Ingrese un nombre por línea',
  chooseColours: 'Elige los colores de la ruleta',
  editColour: 'Editar color',
  save: 'Ahorrar',
  saveSettings: 'Guardar ajustes',
};

export default Roulette;
