const Roulette = {
  roulette: 'Random Wheel',
  rouletteSettings: "Let's set up your giveaway",
  winner: 'Winner',
  holdDraw: 'Hold the Draw',
  informNames: 'Enter one name per line',
  chooseColours: 'Choose the colors of the roulette',
  editColour: 'Edit Color',
  save: 'Save',
  saveSettings: 'Save settings',
};

export default Roulette;
