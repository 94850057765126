import React, { useState, useContext, useEffect } from "react";
import { useRouter } from "next/router";

import Cookies from "js-cookie";
import Image from "next/image";

const SelectLanguageMenu: React.FC = () => {
  const { locale } = useRouter();
  const router = useRouter();
  const languagesOption = ["pt", "en", "es"];
  const [selectedIndexMenu, setSelectedIndexMenu] = useState(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openLang, setOpenLang] = useState(false);

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    Cookies.set("instasorteioLanguage", String(router.locale));
  }, [router.locale]);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    option: string
  ) => {
    let lang: "pt" | "en" | "es" = "pt";
    if (option === "en") {
      lang = "en";
    }
    if (option === "pt") {
      lang = "pt";
    }
    if (option === "es") {
      lang = "es";
    }
    router.push(router.pathname, router.pathname, { locale: lang });
    setSelectedIndexMenu(index);
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        onClick={() => setOpenLang(!openLang)}
        className="w-16 cursor-pointer hover:bg-violet-900 rounded-lg flex h-[35px] 
            gap-2 mr-[40px] sm:mr-[72px] py-[6px] pl-[8px] items-center"
      >
        <div>
          <div className="flex gap-2">
            <Image
              src={`/images/${router.locale}.svg`}
              alt="Logo"
              width={32}
              height={23}
            />
            <Image
              src="/images/endIcon.svg"
              alt="endIcon"
              width={12}
              height={20}
            />
          </div>

          {openLang && (
            <>
              <div className="mt-1 absolute bg-violet-900">
                <div className="flex flex-col gap-2 w-full h-full justify-between">
                  {languagesOption.map((option, index) => (
                    <>
                      <button
                        onClick={(event) =>
                          handleMenuItemClick(event, index, option)
                        }
                        key={option}
                        className="hover:bg-violet-500 px-3 py-3 flex gap-2 items-center"
                      >
                        <Image
                          src={`/images/${option}.svg`}
                          alt="Logo"
                          width={32}
                          height={23}
                        />
                        <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
                          {locale === option && (
                            <div className="bg-blue-500 w-2 h-2 rounded-full" />
                          )}
                        </div>
                      </button>
                    </>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </button>
    </div>
  );
};

export default SelectLanguageMenu;
