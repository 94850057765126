const Home = {
  language: "en",
  init: "Home",
  howItWorks: "How it Works",
  titleTeste: `Make creative giveaways in a`,
  titleSpan: `uncomplicated`,
  subtitleHome: "We facilitate",
  subtitleHome2:
    " your giveaways through a simple platform. Upload thousands of comments and make great giveaways!",
  instagramGiveaway: "Giveaway on Instagram",
  otherGiveaways: "Our",
  otherGiveaways2: "Giveaways",
  trustTitle: "TRUST IN GIVAWAY CONTESTS",

  trustH2: "More than 1 million comments uploaded to Instagram",
  trustBody: "Our algorithm is made to ",
  trustBody2: "load all comments",
  trustBody3:
    "rom your post and draw a winner in a totally random and reliable way!",
  otherDrawsH2: "Choose a giveaway",
  otherDrawsBody:
    "We are the most complete platform for sweepstakes, that's why we have solutions",
  otherDrawsBody2: "for giveaways on Instagram and also for",
  otherDrawsBody3: "all types of giveaways!",
  startNowH2: "Start now!",
  startNowBody: "Upload thousands of comments",
  startNowBody2: "and make huge giveaways!",
  lastCardGrid: "It is all safe   and for free!",
  howDoesItWorkBody:
    /* "Instasorteio is free for publications with up to 300 comments uploaded. To upload more comments, pay only R$59.99 per draw *for publications with up to 60,000 comments. **The paid drawing can be held within 7 days.", */
    "To enter your comments, pay only R$59.99 per raffle *value valid for posts with up to 60,000 comments, check prices for larger raffles. **The paid raffle can be held within 7 days.",
  connectWithFb: "Connect with your Facebook and",
  connectWithFb2: "reduce by up to 40%",
  connectWithFb3: "the loading time",
  influencersSubtitle: "Spy those who also use our platform",
  influencersSubtitle2: "More than 10 million people use our platform.",
  understandTheVip: "Understand the VIP",
  understandTheVipBody:
    "Watch the video until the end to understand every detail of this feature that gives you advantages.",
  meetOurApp: "Meet our Apps",
  meetOurAppBody:
    "The ease of drawing draws now in the palm of your hand. Download from the button below.",
  copyright: "Copyright 2021.",
  copyright2: "All rights reserved.",
  followUs: "Follow Us!",
  giveaways: "Giveaways",
  numbers: "Numbers Giveaway",
  names: "Names Giveaway",
  wheel: "Wheel Giveaway",
  flag: "USFlag",
  follow: "Follow",
  help: "Help",
  sections: "Sections",

  //
  dice: "Dice Giveaway",
  InstaDescriptionApps:
    "Select a post on your Instagram profile and easily make a giveaway with your comments!",
  FbDescriptionApps:
    "As with Instagram, the Facebook giveaway happens in a dynamic, safe and very practical way.",
  DiceDescriptionApps:
    "Select from one to six dice to play virtually. The tool even lists the numbers drawn for you.",
  NumbersDescriptionApps:
    "Choose the range of numbers, the number of draws and whether you want the same number to be drawn more than once.",
  NamesDescriptionApps:
    "Add the names of those involved, decide which settings will be applied and that's it, just draw.",
  WheelDescriptionApps:
    "Add the items you want to give away, customize and present the winner in a fun way.",
  TikTokDescriptionApps:
    "Now our tool is also available for the most downloaded app worldwide.",
  TwitterDescriptionApps:
    "It is also possible to give away a publication on Twitter, one of the most engaged social networks in the world.",
  YouTubeDescriptionApps:
    "Making giveaways contests on your Youtube videos is great news and now, with our platform, it will be possible.",
  modalTitle: "Our Giveaways",
  otherGiveawaysButton: "Our Giveaways",
  modalSubtitle: "Select an option  to start a giveaway.",
  soon: "Soon",
};

export default Home;
