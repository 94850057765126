const Home = {
  language: "pt",
  init: "Início",
  howItWorks: "Como Funciona",
  titleTeste: `Sorteio no Instagram de forma criativa e`,
  titleSpan: `descomplicada`,
  subtitleHome: "Facilitamos seus sorteios ",
  subtitleHome2:
    "por meio de uma plataforma simples. Carregue milhares de comentários e faça sorteios grandiosos!",
  instagramGiveaway: "Realizar meu Sorteio no Instagram",
  otherGiveaways: "Nossos",
  otherGiveaways2: "Sorteios",
  trustTitle: "CONFIANÇA EM SORTEIOS",

  trustH2: "Mais de 1 milhão de comentários carregados no Instagram",
  trustBody: "Nosso algoritmo é feito",
  trustBody2: " para carregar todos os comentários",
  trustBody3:
    " da sua publicação e sortear um vencedor de forma totalmente aleatória e confiável!",
  otherDrawsH2: "Escolha um tipo de sorteio",
  otherDrawsBody:
    "Temos a solução para todos os tipos de sorteio em um só lugar!",
  otherDrawsBody2:
    "Escolha qual se encaixa melhor à sua finalidade e aproveite",
  otherDrawsBody3: "todos os formatos do InstaSorteio.",
  startNowH2: "Comece agora!",
  startNowBody: "Carregue milhares de comentários",
  startNowBody2: "e realize sorteios grandiosos!",
  lastCardGrid: "É tudo muito seguro   e gratuito!",
  howDoesItWorkBody:
    /* "O instasorteio é totalmente gratuito para publicações com até 300 comentários  carregados. Para carregar mais comentários pague apenas R$59,99 por sorteio *valor válido para publicações com até 60  mil comentários, consulte valores para sorteios maiores.  **O sorteio pago pode ser realizado em até 7 dias.", */
    "Para carregar seus comentários pague apenas R$59,99 por sorteio *valor válido para publicações com até 60 mil comentários, consulte valores para sorteios maiores. **O sorteio pago pode ser realizado em até 7 dias.",
  connectWithFb: "Conecte-se com seu Facebook",
  connectWithFb2: " e reduza em até 40%",
  connectWithFb3: "o tempo de carregamento.",
  influencersSubtitle: "Espia só quem também usa nossa plataforma",
  influencersSubtitle2:
    "Mais de 10 milhões de pessoas utilizam nossa plataforma.",
  understandTheVip: "Entenda o sorteio pago",
  understandTheVipBody:
    "Assista ao vídeo até o final para entender cada detalhe dessa feature que te dá vantagens.",
  meetOurApp: "Conheça o aplicativo",
  meetOurAppBody:
    "A facilidade de fazer sorteios agora na palma da sua mão. Faça o download pelo botão abaixo.",
  copyright: "Copyright 2021.",
  copyright2: "Todos os Direitos Reservados.",
  followUs: "Siga a gente!",
  giveaways: "Sorteios",
  numbers: "Sorteio de Números",
  names: "Sorteio de Nomes",
  wheel: "Sorteio de Roleta",
  flag: "BrFlag",
  follow: "Seguir",
  help: "Ajuda",
  sections: "Sessões",

  //
  dice: "Sorteio de Dados",
  InstaDescriptionApps:
    "Selecione uma publicação em seu perfil do Instagram e sorteie os comentários com facilidade!",
  FbDescriptionApps:
    "Assim como no Instagram, o sorteio do facebook acontece de maneira dinâmica, segura e muito prática.",
  DiceDescriptionApps:
    "Selecione de um a seis dados para jogar virtualmente. A ferramenta ainda faz a listagem dos números sorteados para você.",
  NumbersDescriptionApps:
    "Escolha o intervalo de números, a quantidade de sorteados e se quer que o mesmo número seja sorteado mais de uma vez.",
  NamesDescriptionApps:
    "Adicione os nomes dos envolvidos, decida quais as configurações serão aplicadas e pronto, só sortear.",
  WheelDescriptionApps:
    "Adicione os itens que você quer sortear, personalize e apresente o ganhador de uma maneira divertida.",
  TikTokDescriptionApps:
    "Agora nossa ferramenta também está disponível para o app mais baixado no mundo inteiro.",
  TwitterDescriptionApps:
    "Também é possível sortear publicações no Twitter, uma das redes sociais com maior engajamento do mundo.",
  YouTubeDescriptionApps:
    "Fazer sorteios em seus vídeos do Youtube é uma grande novidade e agora, com a nossa plataforma, vai ser possível.",
  modalTitle: "Nossos Sorteios",
  otherGiveawaysButton: "Veja outros Sorteios",
  modalSubtitle: "Selecione uma opção para iniciar um sorteio.",
  soon: "Em breve",
};

export default Home;
