const DiceDraw = {
  diceDraw: 'Roll dice',
  selectTheAmountOfDataBelow: 'Select the amount of dice bellow:',
  rollDice: 'Roll Dice',
  rollAllDice: 'Roll Dice',
  throw: 'Throw',
  numbers: 'Numbers',
  total: 'Total',
  diceThrown: 'Dice Rolled',
  clearLog: 'Clear Log',
};

export default DiceDraw;
