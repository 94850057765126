const Names = {
  namesDraw: 'Sorteio de Nomes',
  writeDownATitle: 'Escreva um Título',
  names: 'Nomes',
  namesOnePerLine: 'Nomes - Escreva um nome por linha',
  insertOneNamePerLine: 'Insira um nome por linha',
  startConfig: 'Iniciar configurações',
  substitutes: 'Suplentes',
  settings: 'Configurações',
  filterDoubled: 'Filtrar duplicados',
  filterDoubledYes: 'Sim',
  filterDoubledNo: 'Não',
  participants: 'Participantes',
  back: 'Voltar',
  winners: 'Vencedores',
};

export default Names;
