const NumbersDraw = {
  subtitleNumbers: 'Dibuja uno o más números dentro del rango elegido 😆',
  numbersToBeDrawn: 'La cantidad de números a sacar',
  pickNumbersBetween: 'Dibujar números entre (mín)',
  maxNumber: 'e (máx)',
  allowDuplicates: 'Permitir números duplicados',
  result: 'Resultado',
  minMaxError:
    'El valor mínimo debe ser mayor o igual que el valor máximo del rango',
  numbersToBeDrawnShort: 'Cantidad nº dibujados',
  range: 'Rotura',
  cleanLog: 'Historia limpia',
  draw: 'Rifa',
  drawAgain: 'Sortea de nuevo',
};

export default NumbersDraw;
