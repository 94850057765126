const NumbersDraw = {
  subtitleNumbers: 'Draw one or more numbers within the chosen range 😆',
  numbersToBeDrawn: 'Amount of numbers to be drawn',
  pickNumbersBetween: 'Draw numbers between (min)',
  maxNumber: 'and (max)',
  allowDuplicates: 'Allow duplicate numbers',
  result: 'Result',
  minMaxError:
    'The minimum value must be greater than or equal to the maximum value of the range',
  numbersToBeDrawnShort: 'Qnt. nº drawn',
  range: 'Range',
  cleanLog: 'Clear Log',
  draw: 'Raffle',
  drawAgain: 'Draw again',
};

export default NumbersDraw;
