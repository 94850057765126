const Names = {
  namesDraw: 'Sorteo de nombre',
  writeDownATitle: 'Escribir un título ',
  names: 'Nombres',
  namesOnePerLine: 'Nombres - Escribe un nombre por línea',
  insertOneNamePerLine: 'Ingrese un nombre por línea',
  startConfig: 'Configuración de inicio',
  substitutes: 'Suplentes',
  settings: 'Ajustes',
  filterDoubled: 'Filtrar duplicados',
  filterDoubledYes: 'Sí',
  filterDoubledNo: 'No',
  participants: 'Participantes',
  back: 'Vuelve',
  winners: 'Ganadores',
};

export default Names;
