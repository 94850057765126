const Menu = {
  home: "Home",
  ourApps: "Other Giveaways",
  howItWorks: "How Does it Work",
  digitalInfluencer: "Digital Influencer",
  otherGiveaways: "Other Giveaways",
  theApp: "The App",

  influencers: "Influencers",
  drawLots: "Draw Lots",
  contactUs: "Contact Us",
  termsOfUse: "Terms of Use",
  privacyPolicy: "Privacy Policy",
  language: "Language",
  copyrights: "Copyright 2021. All rights reserved.",
  // Nossos App
  drawInstagram: "Giveaway on Instagram",
  drawFacebook: "Giveaway on Facebook",
  drawNumbers: "Random Numbers",
  drawNames: "Random Names",
  drawDice: "Roll Dice",
  drawRoulette: "Wheel",
  drawTwitter: "Giveaway on Twitter",
  drawYoutube: "Giveaway on Youtube",
  drawTikTok: "Giveaway on Tiktok",
};

export default Menu;
