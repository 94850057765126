const Roulette = {
  roulette: 'Sorteio de Roleta',
  rouletteSettings: 'Vamos configurar seu sorteio',
  winner: 'Vencedor',
  holdDraw: 'Realizar Sorteio',
  informNames: 'Informe um nome por linha',
  chooseColours: 'Escolha as cores da roleta',
  editColour: 'Editar Cor',
  save: 'Salvar',
  saveSettings: 'Salvar Configurações',
};

export default Roulette;
