const DiceDraw = {
  diceDraw: 'tirar el dado',
  selectTheAmountOfDataBelow: 'Seleccione la cantidad de datos a continuación:',
  throw: 'juego',
  rollDice: 'tirar el dado',
  rollAllDice: 'tirar el dado',
  numbers: 'Números',
  total: 'Total',
  diceThrown: ' Dados lanzados',
  clearLog: 'Historia limpia',
};

export default DiceDraw;
